import React, { Component } from 'react';
import { Consumer } from '../../context';

class ImagenesModal extends Component {
    constructor(props) {
		super(props);
		this.state = {
			sizeModal: "column dinamic-modal-container dinamic-modal-normal",
			dropFile: null
		}
	}

	componentDidMount() {
		this.uploadFile();
	}

	imgError(event) {
		const img = event.target;
		img.src = require('../../images/NoImage.png');
	}

	async uploadFile() {
		const fileSelect	= document.getElementById(`${this.props.idModal}file-upload`);
		const fileDrag      = document.getElementById(`${this.props.idModal}file-drag`);
		const component 	= this;

		function Init() {
			fileSelect.addEventListener('change', fileSelectHandler, false);

			// Is XHR2 available?
			var xhr = new XMLHttpRequest();
			if (xhr.upload) {
				// File Drop
				fileDrag.addEventListener('dragover', fileDragHover, false);
				fileDrag.addEventListener('dragleave', fileDragHover, false);
				fileDrag.addEventListener('drop', fileSelectHandler, false);
			}
		}

		function fileDragHover(e) {		
			e.stopPropagation();
			e.preventDefault();
		
			fileDrag.className = (e.type === 'dragover' ? 'modal-body file-upload hover' : 'modal-body file-upload');
		}

		function fileSelectHandler(e) {
			// Fetch FileList object
			var files = e.target.files || e.dataTransfer.files;
		
			// Cancel event and hover styling
			fileDragHover(e);

			parseFile(files[0]);
			component.setState({dropFile: files[0]});
		}

		// Output
		function output(msg) {
			// Response
			var m = document.getElementById(`${component.props.idModal}messages`);
			m.innerHTML = msg;
		}

		function parseFile(file) {
			output(
				'<strong>' + encodeURI(file.name) + '</strong>'
			);
	
			var imageName = file.name;
		
			var isGood = (/\.(?=gif|jpg|png|jpeg)/gi).test(imageName);
			if (isGood) {
				// Thumbnail Preview
				document.getElementById(`${component.props.idModal}file-image`).classList.remove("hidden");
				document.getElementById(`${component.props.idModal}file-image`).src = URL.createObjectURL(file);
			}
		}

		// Check for the various File API support.
		if (window.File && window.FileList && window.FileReader) {
			Init();
		} else {
			document.getElementById(`${component.props.idModal}file-drag`).style.display = 'none';
		}
	}

	closeModal() {
		const modal = document.getElementById(this.props.idModal || "image-modal");
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

	handleSubmit() {
		const input = document.getElementById(`${this.props.idModal}file-upload`);
		const dropFile = this.state.dropFile;

		if (input) {
			const { setFile } = this.props;
			if (setFile) {
				const reader  = new FileReader();

				reader.onloadend = function () {
					const file = input.files[0] || dropFile;
					setFile(file, reader.result);
				}

				if (input.files[0]) {
					reader.readAsDataURL(input.files[0]);
				} else if (dropFile) {
					reader.readAsDataURL(dropFile);
				}
			}
		}
	}

    render() {
        return (
			<div className= "overlay hide-dinamic-modal imagenUpload column align-center image-modal" id={this.props.idModal || "image-modal"}>
				<div className="column full full-height justify-center align-center">
					<div className="">
						<img src={this.props.imagen} id={`${this.props.idModal}file-image`} onError={this.imgError.bind(this)}></img>
					</div>
					<div className="white-space-8"></div>
					<div className="column upload">
						<div id="file-upload-form" className="uploader column align-center">
							<input id={`${this.props.idModal}file-upload`} type="file" name="fileUpload" accept="image/*" />
							<label htmlFor={`${this.props.idModal}file-upload`} id={`${this.props.idModal}file-drag`}>
								<div id="start">
									<div className="content">
										<i className="fa fa-download" aria-hidden="true"></i>
										<div>Select or drop file here</div>
									</div>
									<div id="notimage" className="hidden">Select a valid image</div>
								</div>
								<div id="response" className="hidden">
									<div id={`${this.props.idModal}messages`}></div>
									<progress className="progress" id="file-progress" value="0">
										<span>0</span>%
									</progress>
								</div>
							</label>
							<div className="white-space-8"></div>
							<div className="row full align-center justify-center">
								<button type="button" className="btn-action-success" onClick={this.handleSubmit.bind(this)}>
									<i className="fas fa-check" /> {' Update'}
								</button>
								<button type="button" className="btn-action-cancel" onClick={this.closeModal.bind(this)}>
									<i className="fas fa-times" /> {' CLose'}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
        );
	}
}



export default Consumer(ImagenesModal);