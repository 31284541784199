
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider, Consumer } from './context';
// contenido
import Login from './pages/login';
import Soon from "./pages/soon";
import Usuarios from "./pages/usuarios";
import Perfiles from "./pages/perfiles";
//Modulos
import Presupuestos from "./modules/presupuestos";
import Clientes from "./modules/clientes";
import OrdenesCompra from "./modules/ordenesCompra";
import OrdenesTrabajo from "./modules/ordenesTrabajo";
import Facturas from "./modules/facturas";
import Pagos from "./modules/pagos";
import Productos from "./modules/productos";
import Pedidos from "./modules/pedidos";

const PrivateRoute = Consumer (
	({ component: Component, context, ...rest }) => {
		return (
			<Route
				{...rest}
				render={
					props => {
						if (context.auth) {
							return <Component {...props} />;
						} else {
							return (
								<Redirect to={{ pathname: "/", state: { from: props.location } }} />
							);
						}
					}
				}
			/>
		)
	}
);

class App extends React.Component {
  
  	render() {
    	return (
			<BrowserRouter>
				<Provider>
					<div className="main">
						<Switch>
							<Route path="/" exact component={ Login }></Route>
							<PrivateRoute path="/usuarios" exact component={Usuarios}/>
							<PrivateRoute path="/perfiles" exact component={Perfiles}/>
							<PrivateRoute path="/home" exact component={Soon}/>
							<PrivateRoute path="/presupuestos" exact component={Presupuestos}/>
							<PrivateRoute path="/clientes" exact component={Clientes}/>
							<PrivateRoute path="/ordenestrabajo" exact component={OrdenesTrabajo}/>
							<PrivateRoute path="/ordenescompra" exact component={OrdenesCompra}/>
							<PrivateRoute path="/facturas" exact component={Facturas}/>
							<PrivateRoute path="/pagos" exact component={Pagos}/>
							<PrivateRoute path="/productos" exact component={Productos}/>
							<PrivateRoute path="/pedidos" exact component={Pedidos} />
						</Switch>
					</div>
				</Provider>
			</BrowserRouter>
    	);
  	}
}

export default App;