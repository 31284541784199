import React from 'react';
import img from '../images/loginBG.jpg';
import CardLogin from "../components/loginCard";
import { Consumer } from '../context';
import { withRouter } from 'react-router-dom';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    
    render() {
        return (
            <div className="row full login-view">
                <div className="column logo-column full" style={{ backgroundImage: `url(${img})` }} >
                    <div className="overlay-login"></div>
                </div>
                <div className="column full form-column justify-center align-center">
                    <CardLogin></CardLogin>
                </div>
            </div>
        )
    }
}

export default withRouter(Consumer(Login));