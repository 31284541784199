import React from 'react';
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import UsuarioForm from "../../components/usuarios/form";
import { Consumer } from "../../context";
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import Request from '../../api/apiService';
import usuarios from '../../pages/usuarios';
const req = new Request();

class TablaUsuarios extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
			itemUser: [],
			usuario: {}
        }
    }


    openModal(idModal, item) {
        const modal = document.getElementById(idModal);
        this.setState({usuario: item});
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		this.setState({usuario: {}});
		if(modal) {
			modal.classList.remove('show-dinamic-modal');
			modal.classList.add('hide-dinamic-modal');
		}
	}

    inputChange(key, event) {
        const itemUser = this.state.itemUser ;
        itemUser [key] = event.target.value;
        this.setState({itemUser});
    }

    clearData() {
        this.setState({itemUser: []})
	}

    async editUser(event) {
        this.setState({loadingSave: true, message: ''});
        event.preventDefault();
        const form = event.target;
		const { context } = this.props;
		const id_usuario = await context.getId(context.token);
		const { usuario } = this.state;
		const modificado = Array.isArray(usuario.modificado) ? usuario.modificado : [];
		modificado.push({id_usuario, fecha: moment().format('YYYY-MM-DD HH:mm:ss')});

        const data = {
			id_usuario	:	usuario.id_usuario,
            nombre      :   form.name.value,
            correo      :   form.email.value,
            usuario     :   form.user.value,
            password    :   form.pass.value,
			id_perfil   :   form.role.value,
			token		:	context.token,
			fecha		:	moment().format('YYYY-MM-DD HH:mm:ss'),
			creado		: 	usuario.creado,
			modificado,
			id_modulo	:	this.props.id_modulo
        }

        const res = await req.post('usuario/editar', data);

		if(res.updated) {
			cogoToast.success('Usuario Actualizado', { position: 'top-right', heading: 'ACTUALIZADO!' });
			this.closeModal('editarUsuario');
            this.props.reload();
        } else {
			cogoToast.error(res.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({message: data.message, loadingSave: false});
		}
        this.setState({loadingSave: false});
    }


    render() {
        let usuarios = [];
        if(Array.isArray(this.props.usuarios)) {
            usuarios = this.props.usuarios;
        }
        return (
            <div className="row table-container">
                <div className="column full">
                    <div className="row full header-table">
                        <div className="column full">
                            <div className="row">
                                <i className="fas fa-th-list"></i>
                                <span className="color-white weight-semibold">Lista de usuarios existentes</span>
                            </div>
                        </div>
                        <div className="row full justify-end">
                            <div className="row input-search">
                                <input className="" type="text" id="usuario" name="usuario" placeholder="Buscar ..." maxLength="256" required></input>
                                <i className="fas fa-search font-small icon-search"></i>
                            </div>
                        </div>
                    </div>
                    <div className="white-space-16"></div>
                    <table className="tabla-usuarios">
						<thead>
							<tr>
								<th className="text-left">Nombre</th>
								<th className="text-left">Usuario</th>
								<th className="text-left">Correo</th>
								<th className="text-left">Perfil</th>
								<th className="text-left">Estatus</th>
								<th className="text-center"></th>
							</tr>
						</thead>
						<tbody>
                        {
                        this.props.loading?
                            <tr>
                                <td colSpan="12">
                                    <div className="row justify-center">
                                        <i className="fas fa-sync fa-spin"></i>
                                    </div>
                                </td>
                            </tr> :
                        
                            usuarios.map((user, key) => {
                            return(
                                <tr key={key}> 
                                    <td className="text-left">{user.nombre}</td>
                                    <td className="text-left">{user.usuario}</td>
                                    <td className="text-left">{user.correo}</td>
                                    <td className="text-left">{user.perfil}</td>
                                    <td className="text-left">{user.activo === 1 ? "Activo" : "Inactivo"}</td>
                                    <td className="text-center">
                                        <button className="btn-table-blue" onClick={this.openModal.bind(this,'editarUsuario', user)}>
                                            <i className="fas fa-user-edit"></i>
                                        </button>
                                    </td>
                                </tr>
                            )}
                        )
                        }
						</tbody>
                    </table>
                </div>
                <DinamicModal 
					idModal					=	{'editarUsuario'}
					sizeModalContainer		=	{'normal'}
					title					=	{'Editar información del usuario'} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"CANCELAR"}
                    loadingSave				=	{this.state.loadingSave}
                    success					=	{this.editUser.bind(this)} 
                    closeModal              =   {this.clearData.bind(this)}
					>
                        <UsuarioForm values = {this.state.usuario}/>
                </DinamicModal>
            </div>
        )
    }
}

export default Consumer(TablaUsuarios);