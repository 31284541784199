import React from 'react';
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import PedidoForm from "../../components/pedidos/form";
import Request from '../../api/apiService';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import { Consumer } from '../../context';
import { formatNumber } from '../../context/Actions/numbers';
import Paginador from '../paginador/paginador';
import Controles from './controles';
const req = new Request();

let pedido = {};

class TablaPedidos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			id_modulo: 10,
			page: 1,
            loading: false,
			producto: {},
			filtro: ''
		};
		this.formValues = {};
		this.imgDropzone = null;
    }

	componentDidMount() {
	}

    openModal(idModal, item) {
		pedido = item;
		this.setState({pedido});
        const modal = document.getElementById(idModal);

		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }
	
	closeModal(idModal) {
		pedido = {};
		this.setState({pedido});
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
    }

    clearData() {
		this.formValues = {};
		this.setState({pedido: {}});
    }

    async editOrder(event) {
		this.setState({loading: true});
		event.preventDefault();
		const { context } = this.props;
		const id_usuario = await context.getId(context.token);
		const { pedido, id_modulo } = this.state;
		const modificado = Array.isArray(pedido.modificado) ? pedido.modificado : [];
		modificado.push({id_usuario, fecha: moment().format('YYYY-MM-DD HH:mm:ss')});
		const data = {
			id_modulo,
			id_pedido: this.state.pedido.id_pedido,
			estado: this.formValues.estado,
			modificado
		};
		const res = await req.post('admin/pedido/editar', data);
		if(res.updated) {
			cogoToast.success('Order updated', { position: 'top-right', heading: 'UPDATED!' });
			this.closeModal('editPedido');
			this.props.reload();
		} else {
			cogoToast.error(res.message || 'Internal error.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({message: data.message, loadingSave: false});
		}
		this.setState({loading: false});
    }

	setPage(page) {
		this.setState({page});
	}

	filtrar(elementos, filtros) {
		let result = [];
		if (Array.isArray(elementos) && Array.isArray(filtros)) {
			filtros.forEach(campo => {
				elementos.forEach(elemento => {
					let value = elemento[campo];
					value = value.toLowerCase();
					if (value.indexOf(this.state.filtro) !== -1) {
						result.push(elemento);
					}
				})
			});
			//Evitar duplicados
			result = result.filter(function (element, pos, array) {
				return array.map((mapItem) => {
					return mapItem;
				}).indexOf(element) === pos;
			});
			return result;
		} else {
			return elementos;
		}
	}

    render() {
		const { context } = this.props.context;
		let pedidos = [];
		let pages = 1;
        if(Array.isArray(this.props.pedidos)) {
			pedidos = this.filtrar(this.props.pedidos, ['producto']);
			pages = Math.ceil(pedidos.length / 10);
			pedidos = pedidos.slice(((this.state.page - 1) * 10), (this.state.page * 10));
        }
        return (
            <div className="row table-container">
                <div className="column full">
					<Controles
						setFiltro 	= 	{(filtro) => this.setState({filtro})}
						title		=	"Orders List"
					/>
                    <div className="white-space-16"></div>
                    <table className="tabla-productos">
						<thead>
							<tr>
								<th className="text-left">Name</th>
								<th className="text-right">Total</th>
								<th className="text-center">Type</th>
								<th className="text-center">Delivery Date</th>
								<th className="text-center">Status</th>
								<th className="text-center"></th>
							</tr>
						</thead>
						<tbody>
                        {
							this.props.loading?
								<tr>
									<td colSpan="6">
										<div className="row justify-center full">
											<i className="fas fa-sync fa-spin"></i>
										</div>
									</td>
								</tr> :
							
								pedidos.map((pedido, key) => {
								return(
									<tr key={key}> 
										<td className="text-left">
											{pedido.producto}
										</td>
										<td className="text-right">
											{formatNumber(pedido.total, 2, '.', ',','$')}
										</td>
										<td className="text-center">
											{pedido.tipo_envio}
										</td>
										<td className="text-center">
											{moment(pedido.fecha_envio).format('DD/MM/YYYY')}
										</td>
										<td className="text-center">
											{pedido.estado}
										</td>
										<td className="text-center">
											<button className="btn-table-green" onClick={this.openModal.bind(this,'editPedido', pedido)}>
												<i className="fas fa-edit"></i>
											</button>
										</td>
									</tr>
								)}
							)
                        }
						</tbody>
                    </table>
					<Paginador setPage={this.setPage.bind(this)} pages={pages}/>
                </div>
                <DinamicModal 
					idModal					=	{'editPedido'}
					sizeModalContainer		=	{'normal'}
					title					=	{`Order Detail`} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"Save"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"Cancel"}
                    loadingSave				=	{this.state.loading}
                    success					=	{this.editOrder.bind(this)} 
					closeModal              =   {this.clearData.bind(this)}
					>
                        <PedidoForm
							values 		= 	{this.state.pedido}
							getValues	=	{(values) => this.formValues = values}
							categorias	=	{this.props.categorias}/>
                </DinamicModal>
            </div>
        )
	}
}//end class

export default Consumer(TablaPedidos);