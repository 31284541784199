import React from 'react';
import Request from '../api/apiService';

// Actions
import { loginSuccess, logOut, loadUser, getPermisos, getId } from './Actions/users';
import { loadModulos } from './Actions/modulos';
import { getPerfiles } from './Actions/perfiles';
import { setImagen, setFileImagen } from './Actions/imagen';

const req = new Request();

let state = window.localStorage.getItem('aroos_user');
if(state) {
    state = JSON.parse(state);
}

const Context = React.createContext('app');

export class Provider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: this.checkAuth(),
            token: this.checkToken(),
            getPermisos: getPermisos.bind(this),
			loginSuccess: loginSuccess.bind(this),
            logout: logOut.bind(this),
			loadUser: loadUser.bind(this),
			modulos: [],
            loadModulos: loadModulos.bind(this),
            perfiles: [],
			getPerfiles: getPerfiles.bind(this),
			getId: getId.bind(this),
			imagen: '',
			setImagen: setImagen.bind(this),
			setFileImagen: setFileImagen.bind(this)
        }
    }

	componentDidMount() {
        this.state.loadModulos();
        this.state.getPerfiles();
	}

    checkAuth() {
        if (window.localStorage.getItem('aroos_user')) {
            const session = JSON.parse(window.localStorage.getItem('aroos_user'));
            return session.auth;
        }
    }

    checkToken() {
        if (window.localStorage.getItem('aroos_user')) {
            const session = JSON.parse(window.localStorage.getItem('aroos_user'));
            return session.token;
        }
    }

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        )
    }
}

export function Consumer(Component) {
    return function WrapperComponent(props) {
        return (
            <Context.Consumer>
                {state => <Component {...props} context={state} />}
            </Context.Consumer>
        );
    };
}