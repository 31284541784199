import React from 'react';
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import ProductoForm from "../../components/products/form";
import Request from '../../api/apiService';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import { Consumer } from '../../context';
import { formatNumber } from '../../context/Actions/numbers';
import CheckBox from '../checkbox';
import Paginador from '../paginador/paginador';
import Controles from './controles';
const req = new Request();

let producto = {};
let removeAll = false;
class TablaProductos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			id_modulo: 9,
			page: 1,
            loading: false,
			producto: {},
			filtro: ''
		};
		this.formValues = {};
		this.imgDropzone = null;
    }

	componentDidMount() {
		//DROPZONE
		//this.initDropzone();
	}

	/* initDropzone() {
		const host = 'https://apiaroos.mrdserver.com';
		//const host = 'http://localhost:8081';
		this.imgDropzone = new window.Dropzone("div#dropzone-element", { 
			url: `${host}/admin/producto/upload/imagen`,
			headers: {"api_key": "aroos_1309"},
			paramName: "file",
			maxFilesize: 1, // MB
			maxFiles: 5,
			addRemoveLinks: true,
			acceptedFiles: "image/*",
			removedfile: function(file) {
				//console.log('removeAll', removeAll);
				async function borraImagen(file) {
					const { id_producto } = producto;
					if (file.status === 200 || file.status === 'success') {
						const data = {
							id_producto,
							fileName: file.name
						};
						const res = await req.post('admin/producto/delete/imagen', data);
						if (res.deleted) {
							file.previewElement.remove();
							//cogoToast.error('', {  position: 'top-right', heading: 'Removed' });
						} else {
							cogoToast.error('Could not be removed', {  position: 'top-right', heading: 'OOPS!' });
						}
					} else {
						file.previewElement.remove();
					}
				};
				if (removeAll) {
					file.previewElement.remove();
				} else {
					borraImagen(file);
				}
			},
			init: function() {
				this.on("sending", function(file, xhr, formData) {
					formData.append("id_producto", producto.id_producto);
				});	
			}
		});
	} */

    openModal(idModal, item) {
		removeAll = true;
		producto = item;
        const modal = document.getElementById(idModal);
		this.setState({producto});

		if(modal) {
			const { imagenes } = producto;
			if (Array.isArray(imagenes)) {
				if (imagenes.length > 0) {
					imagenes.forEach(img => {
						const host = 'https://aroosdocuments.mrdserver.com';
						const file = {
							name: img.name,
							signedUrl: `${host}/${img.path}`,
							size: img.size
						};
					});
				}
			}
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
		removeAll = false;
    }
	
	closeModal(idModal) {
		producto = {};
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
    }

    clearData() {
		this.formValues = {};
		this.setState({producto: {}});
    }

    async editProduct(event) {
		this.setState({loading: true});
		event.preventDefault();
		const { context } = this.props;
		const id_usuario = await context.getId(context.token);
		const { producto, id_modulo } = this.state;
		const modificado = Array.isArray(producto.modificado) ? producto.modificado : [];
		modificado.push({id_usuario, fecha: moment().format('YYYY-MM-DD HH:mm:ss')});
		const data = {
			id_modulo,
			id_producto: this.state.producto.id_producto,
			id_categoria: this.formValues.id_categoria,
			nombre: this.formValues.nombre,
			descripcion: this.formValues.descripcion,
			precio: this.formValues.precio,
			activo: this.formValues.activo,
			categorias: this.formValues.categorias,
			imagenes: [],
			modificado
		}
		const res = await req.post('admin/producto/editar', data);
		if(res.updated) {
			cogoToast.success('Product updated', { position: 'top-right', heading: 'UPDATED!' });
			this.closeModal('editProducto');
			this.props.reload();
		} else {
			cogoToast.error(res.message || 'Internal error.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({message: data.message, loadingSave: false});
		}
		this.setState({loading: false});
    }

	setPage(page) {
		this.setState({page});
	}

	filtrar(elementos, filtros) {
		let result = [];
		if (Array.isArray(elementos) && Array.isArray(filtros)) {
			filtros.forEach(campo => {
				elementos.forEach(elemento => {
					let value = elemento[campo];
					value = value.toLowerCase();
					if (value.indexOf(this.state.filtro) !== -1) {
						result.push(elemento);
					}
				})
			});
			//Evitar duplicados
			result = result.filter(function (element, pos, array) {
				return array.map((mapItem) => {
					return mapItem;
				}).indexOf(element) === pos;
			});
			return result;
		} else {
			return elementos;
		}
	}

    render() {
		let productos = [];
		let pages = 1;
        if(Array.isArray(this.props.productos)) {
			productos = this.filtrar(this.props.productos, ['nombre']);
			pages = Math.ceil(productos.length / 10);
			productos = productos.slice(((this.state.page - 1) * 10), (this.state.page * 10));
        }
        return (
            <div className="row table-container">
                <div className="column full">
					<Controles
						setFiltro 	= 	{(filtro) => this.setState({filtro})}
						title		=	"Product List"
					/>
                    <div className="white-space-16"></div>
                    <table className="tabla-productos">
						<thead>
							<tr>
								<th className="text-left">Name</th>
								<th className="text-left">Price</th>
								<th className="text-center">Active</th>
								<th className="text-center"></th>
							</tr>
						</thead>
						<tbody>
                        {
							this.props.loading?
								<tr>
									<td colSpan="5">
										<div className="row justify-center full">
											<i className="fas fa-sync fa-spin"></i>
										</div>
									</td>
								</tr> :
							
								productos.map((producto, key) => {
								return(
									<tr key={key}> 
										<td className="text-left">
											{producto.nombre}
										</td>
										<td className="text-left">
											{formatNumber(producto.precio, 2, '.', ',','$')}
										</td>
										<td className="text-center">
											<div className="column justify-center align-center">
												<CheckBox checked = {producto.activo? true : false}/>
											</div>
										</td>
										<td className="text-center">
											<button className="btn-table-green" onClick={this.openModal.bind(this,'editProducto', producto)}>
												<i className="fas fa-edit"></i>
											</button>
										</td>
									</tr>
								)}
							)
                        }
						</tbody>
                    </table>
					<Paginador setPage={this.setPage.bind(this)} pages={pages}/>
                </div>
                <DinamicModal 
					idModal					=	{'editProducto'}
					sizeModalContainer		=	{'big'}
					title					=	{`Edit Product`} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"Save"}
					showBtnCancel			=	{true}
					btnTextCancel			=	{"Cancel"}
                    loadingSave				=	{this.state.loading}
                    success					=	{this.editProduct.bind(this)} 
					closeModal              =   {this.clearData.bind(this)}
					>
                        <ProductoForm
							values 		= 	{this.state.producto}
							getValues	=	{(values) => this.formValues = values}
							categorias	=	{this.props.categorias}
							upImagenes	=	{true}/>
                </DinamicModal>
            </div>
        )
	}
}//end class

export default Consumer(TablaProductos);