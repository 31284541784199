import React, { useEffect, useState } from 'react';
import Sidebar from "../components/sideBar";
import TablePedidos from "../components/tables/pedidosTabla";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import PedidoForm from "../components/pedidos/form";
import { Consumer } from '../context';
// TOASR ALERTS & LOADING SPINERS
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import Request from '../api/apiService';

const req = new Request();

const Pedidos = () => {
	const [ id_modulo, setIdModulo ] = useState(10);
	const [ usuario, setUsuario ] = useState({});
	const [ loading, setLoading ] = useState(false);
	const [ lectura, setLectura ] = useState(false);
	const [ escritura, setEscritura ] = useState(false);
	const [ pedidos, setPedidos ] = useState([]);
	const [ categorias, setCategorias ] = useState([]);
	const [ formValues, setFormValues ] = useState({});

    useEffect(() => {
		loadUser();
	}, []);
    
    function openModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	function closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
    }

	async function loadUser() {
        const aroos_user = window.localStorage.getItem("aroos_user");
		let session = JSON.parse(aroos_user);

		const data = {
            token: session.token
		}

        const { token } = await req.post('decode', data);

        if (token) {

			setUsuario(token);

			const { permisos } = token;
			if(permisos) {
				if(permisos.lectura.find(l => l === id_modulo)) {
					await setLectura(true);
					loadPedidos();
				} else {
					cogoToast.error('Could not get permissions.', { position: 'top-right', heading: 'OOPS!' });
				}

				if(permisos.escritura.find(e => e === id_modulo)) {
					await setEscritura(true);
				}
			}

		} else {
			cogoToast.error('Could not get permissions.', { position: 'top-right', heading: 'OOPS!' });
		}
	}

    async function loadPedidos() {
		setLoading(true);

        const data = {
            id_modulo: id_modulo
        }

        const res = await req.get('admin/pedidos/tabla', data);

        if(res) {
			if(res.error) {
				cogoToast.error(res.message, { position: 'top-right', heading: 'OOPS!' });
			}
            setPedidos(res.pedidos);
        } else {
            cogoToast.error('Could not get permissions.', { position: 'top-right', heading: 'OOPS!' });
        }

        setLoading(false);
    }

    function reload() {
        loadUser();
    }

    async function addNewProducto(event) {
        this.setState({loading: true, message: ''});
		event.preventDefault();
		const { context } = this.props;
		const data = {
			id_modulo: this.state.id_modulo,
			id_categoria: this.formValues.id_categoria,
			nombre: this.formValues.nombre,
			descripcion: this.formValues.descripcion,
			precio: this.formValues.precio,
			activo: this.formValues.activo,
			token: context.token,
			fecha_creado: moment().format('YYYY-MM-DD HH:mm:ss')
		}
		const res = await req.post('admin/pedido/crear', data);
		if(res.created) {
			cogoToast.success('New product created', { position: 'top-right', heading: 'SUCCESS!' });
			this.closeModal('nuevoPedido');
			this.reload();
		} else {
			cogoToast.error(res.message || 'Internal error.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({message: data.message, loadingSave: false});
		}
        this.setState({loading: false});
    }

	return (
		<div className="row full view">
			<Sidebar view="usuarios"></Sidebar>
			<div className="column full align-center">
				<div className="container column">

					<div className="row full header-options align-center">
						<div className="column full title">
							<h1 className="weight-light color-white">Orders</h1>
						</div>
						<div className="column full btns-options align-end">
							<div className="row">
							
							<button className="btn-options btn-orange-plus" onClick={openModal.bind(this,'nuevoPedido')} >
								<i className="fas fa-plus font-regular"></i>
							</button>
							</div>
						</div>
					</div>
					<TablePedidos
						pedidos		=	{pedidos}
						loading		=	{loading}
						escritura	=	{escritura}
						reload		=	{reload.bind(this)}/>
				</div>
			</div>
			<DinamicModal 
				idModal					=	{'nuevoPedido'}
				sizeModalContainer		=	{'normal'}
				title					=	{'New Order'} 
				showBtnSuccess			=	{true}
				btnTextSuccess			=	{"Save"}
				showBtnCancel			=	{true}
				btnTextCancel			=	{"Cancel"}
				success                 =   {addNewProducto.bind(this)}
				loadingSave				=	{loading}
				>
					<PedidoForm
						getValues	=	{(values) => setFormValues(values)}/>
			</DinamicModal>
		</div>
	)
}

export default Consumer(Pedidos);
