import React, { useState, useEffect } from 'react';
import { Consumer } from '../../context/index';
import CheckBox from '../checkbox';
import CogoToast from 'cogo-toast';
import { formatNumber } from '../../context/Actions/numbers';
import ImageModal from "../../components/dinamicModal/uploadImage";
import ArrayTool from '../../tools/arrays';

const ProductoForm = (props) => {
	const [imagen, setImagen] = useState(require('../../images/NoImage.png'));
	const [categorias, setCategorias] = useState([]);
	const [categoria, setCategoria] = useState('');
	const [count, setCount] = useState(0);
	const [state, setState] = useState({
		id_tipo: 0,
		nombre: '',
		descripcion: '',
		precio: 0,
		activo: 0,
		tipos: [],
		categorias: []
	});

    useEffect(() => {
        if (props.getValues) {
            props.getValues({
				id_tipo: state.id_tipo,
				nombre: state.nombre,
				descripcion: state.descripcion,
				precio: state.precio,
				activo: state.activo,
				categorias: categorias
			});
		}
	});

	useEffect(() => {
		if (props.values) {
			const { values } = props;
			setState({
				...state,
				id_tipo: values.id_tipo,
				nombre: values.nombre,
				descripcion: values.descripcion,
				precio: values.precio? parseFloat(values.precio).toFixed(2) : 0,
				activo: values.activo
			});

			setCategorias(values.categorias || []);
		}
	}, [props.values]);

	function openImagen(cat) {
		setCategoria(cat.nombre);
		setImagen(cat.imagen || imagen);

		const modal = document.getElementById(props.idModalImagen || 'image-modal');
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	function updateCategoriaImagen(file, img) {
		if (categoria) {
			const fileSelect = document.getElementById(`${props.idModalImagen}file-upload`);
			const modal = document.getElementById(props.idModalImagen || 'image-modal');

			if (modal) {
				modal.classList.add('hide-dinamic-modal');
				modal.classList.remove('show-dinamic-modal');
			}

			if (fileSelect) {
				fileSelect.value = '';
			}

			const cats = [...categorias];

			cats.map(c => {
				if (c.nombre === categoria) {
					c.imagen = img;
					c.file = file;
					c.test = c.nombre;
				}
				return c;
			});

			setCategoria('');
			setImagen(null);
			setCategorias([...cats]);
		}
	};


	function agregarCategoria() {
		const cats = [...categorias];
		const id = ArrayTool.makeId(cats, 'id');
		const nombre = state.cat_nombre;
		const precio = state.cat_precio;

		if (!nombre) {
			CogoToast.error('Provide a valid category name.', { position: 'top-center'});
			return;
		}

		if (!(precio > 0)) {
			CogoToast.error('Provide a valid price.', { position: 'top-center'});
			return;
		}

		if (cats.find(c => c.nombre === nombre)) {
			CogoToast.error('Category already exist.', { position: 'top-center'});
			return;
		}

		cats.push({id, nombre, precio, imagen: require('../../images/NoImage.png')});

		setCategorias([...cats]);
	}

	function deleteCategoria(categoria) {
		let cats = [...categorias];

		cats = cats.filter(cat => cat.id != categoria.id);

		setCategorias([...cats]);
	}

    return (
        <div className="full row-responsive align-center producto-form">
            <div className="column full full-height justify-start">
				<div className="column label-duo-big ">
					<p> Product Definition</p>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-start">
                    <div className="column label-medium ">
                        <p> Type :</p>
                    </div>
                    <div className="column full">
                        <select
							value			=	{state.id_tipo}
							onChange		=	{(input) => setState({...state, id_tipo: input.target.value})}
							name			=	"type"
							id				=	"producto_tipo"
                            className		=	"input input-modals" 
                            required				 
							autoComplete    =   "off"
                        >
							<option value="">Product Type</option>
							<option value="1">Flower</option>
							<option value="2">Gift</option>
							{state.tipos.map(c => <option key={c.id_tipo} value={c.id_tipo}>{c.nombre}</option>)}
						</select>
                    </div>
                </div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p> Name :</p>
                    </div>
                    <div className="column full">
                        <input
							value			=	{state.nombre || ''}
							onChange		=	{(input) => setState({...state, nombre: input.target.value})}
                            type			=	"text" 
							name			=	"name"
							id				=	"producto_nombre"
                            className		=	"input input-modals" 
                            required				 
                            maxLength		=	"150"
							autoComplete    =   "off"
                        />
                    </div>
                </div>
                <div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
                    <div className="column label-medium ">
                        <p> Description :</p>
                    </div>
                    <div className="column full">
                        <textarea
							value			=	{state.descripcion}
							onChange		=	{(input) => setState({...state, descripcion: input.target.value})}
                            type			=	"text" 
							name			=	"descripcion"
							id				=	"producto_descripcion"
                            className		=	"input input-modals-textarea" 
                            required				 
                            maxLength		=	"500"
							autoComplete    =   "off"
							rows			= 	"8"
                        />
                    </div>
                </div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
                    <div className="column label-duo-big ">
                        <p> Price :</p>
                    </div>
                    <div className="column full">
                        <input
							value			=	{state.precio || ''}
							onChange		=	{(input) => setState({...state, precio: input.target.value})}
                            type			=	"number" 
							name			=	"price"
							id				=	"producto_descripcion"
                            className		=	"input input-modals" 
                            required				 
							autoComplete    =   "off"
                        />
                    </div>
                </div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column label-duo-normal">
						<p> Active :</p>
					</div>
					<div className="column full justify-center align-center">
						<CheckBox
							checked={state.activo ? true : false}
							onChange={(value) => setState({...state, activo: value})}/>
					</div>
				</div>
				<div className="white-space-32"></div>
            </div>
			<div className="column full full-height space justify-start">
				<div className="column label-duo-big ">
					<p> Categories</p>
				</div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-center">
					<div className="column full">
						<div className="column label-duo-big ">
							<p>Name :</p>
						</div>
						<input
							value			=	{state.cat_nombre || ''}
							onChange		=	{(input) => setState({...state, cat_nombre: input.target.value})}
							type			=	"text" 
							name			=	"name"
							id				=	"producto_nombre_categoria"
							className		=	"input input-modals" 				 
							maxLength		=	"150"
							autoComplete    =   "off"
						/>
					</div>
					<div className="white-space-8"></div>
					<div className="column space full">
						<div className="column label-duo-big ">
							<p>Price :</p>
						</div>
						<div className="column full">
							<input
								value			=	{state.cat_precio || ''}
								onChange		=	{(input) => setState({...state, cat_precio: input.target.value})}
								type			=	"number" 
								name			=	"cat_price"
								id				=	"producto_cat_price"
								className		=	"input input-modals" 				 
								autoComplete    =   "off"
							/>
						</div>
					</div>
					<div className="column space">
						<button className="btn-options btn-orange-plus" type="button" onClick={agregarCategoria.bind(this)}>
							<i className="fas fa-plus"></i>
						</button>
					</div>
				</div>
				<div className="white-space-8"></div>
				<div className="categories-table full">
					<table>
						<thead>
							<tr>
								<th className="text-left" style={{width: '30%'}}>
									Category
								</th>
								<th className="text-right" style={{width: '30%'}}>
									Price
								</th>
								<th className="text-center" style={{width: '30%'}}>
									Image
								</th>
								<th className="text-center" style={{width: '10%'}}>
								</th>
							</tr>
						</thead>
						<tbody>
							{categorias.map((cat, index) => (
								<tr key={index}>
									<td className="text-left" style={{width: '30%'}}>
										{cat.nombre}
									</td>
									<td className="text-right" style={{width: '30%'}}>
										{formatNumber(cat.precio, 2, '.', ',','$')}
									</td>
									<td className="text-center" style={{width: '30%'}}>
										<img src={cat.imagen || imagen} onClick={openImagen.bind(this, cat)}/>
									</td>
									<td className="text-center" style={{width: '10%'}}>
										<i className="fas fa-trash pointer" onClick={deleteCategoria.bind(this, cat)}></i>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
			<ImageModal
				idModal = {props.idModalImagen}
				setFile = {updateCategoriaImagen.bind(this)} imagen = {imagen} />
        </div>
    )
}

export default Consumer( ProductoForm );