import React from 'react';
import Sidebar from "../components/sideBar";
import TableUsuarios from "../components/tables/usuariosTabla";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import UsuarioForm from "../components/usuarios/form";
import { Consumer } from '../context';
import moment from 'moment-timezone';
import cogoToast from 'cogo-toast';
import Request from '../api/apiService';
const req = new Request();

class Usuarios extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			id_modulo: 7,
            loading: false,
            usuarios: []
        }
    }

    componentDidMount() {
	    this.loadPerfiles();
	}

	async loadPerfiles() {
		const { context } = this.props;
		context.getPerfiles();
		this.loadUsers();
	}

    openModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
    }

    async loadUsers() {
		this.setState({loading: true});
		const { context } = this.props;
		const data = {
			user: context.user,
			id_modulo: this.state.id_modulo
		};
		const res = await req.get('usuarios/tabla', data);
        if(res) {
            this.setState({usuarios: res.usuarios});
        }
        this.setState({loading: false});
    }

    reload() {
        this.loadUsers();
    }

    async addNewUser(event) {
        this.setState({loadingSave: true, message: ''});
		event.preventDefault();

		const form = event.target;
		const { context } = this.props;

        const data = {
            nombre      :   form.name.value,
            correo      :   form.email.value,
            usuario     :   form.user.value,
            password    :   form.pass.value,
			id_perfil   :   form.role.value,
			token		:	context.token,
			fecha		:	moment().format('YYYY-MM-DD HH:mm:ss'),
			id_modulo	:	this.state.id_modulo
        }

        const res = await req.post('usuario/crear', data);

        if(res.created) {
			cogoToast.success('Nuevo Usuario Agregado', { position: 'top-right', heading: 'AGREGADO!' });
			this.closeModal('nuevoUsuario');
            this.reload();
        } else {
			cogoToast.error(res.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({message: data.message, loadingSave: false});
		}
        this.setState({loadingSave: false});
    }

    render() {
        let usuarios = [];
		if(this.state.usuarios) {
			usuarios = this.state.usuarios;
		}
        return (
            
            <div className="row full view">
                <Sidebar view="usuarios"></Sidebar>
                <div className="column full align-center">
                    <div className="container column">

                        <div className="row full header-options align-center">
                            <div className="column full title">
                                <h1 className="weight-light color-white">Usuarios</h1>
                            </div>
                            <div className="column full btns-options align-end">
                                <div className="row">
                                
                                <button className="btn-options btn-orange-plus" onClick={this.openModal.bind(this,'nuevoUsuario')} >
                                    <i className="fas fa-plus font-regular"></i>
                                </button>
                                </div>
                            </div>
                        </div>
                        <TableUsuarios
							usuarios	=	{usuarios}
							loading		=	{this.state.loading}
							reload		=	{this.reload.bind(this)}
							id_modulo	=	{this.state.id_modulo}
						/>
                    </div>
                </div>
                <DinamicModal 
					idModal					=	{'nuevoUsuario'}
					sizeModalContainer		=	{'normal'}
					title					=	{'Agregar nuevo usuario'} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
                    btnTextCancel			=	{"CANCELAR"}
                    success                 =   {this.addNewUser.bind(this)}
					loadingSave				=	{this.state.loadingSave}
					>
                        <UsuarioForm/>
                </DinamicModal>
            </div>
        )
    }
}

export default Consumer(Usuarios);