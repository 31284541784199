import React from 'react';
import Request from '../api/apiService';
import { Consumer } from '../context';
import { withRouter } from 'react-router-dom';

// TOASR ALERTS & LOADING SPINERS
import cogoToast from 'cogo-toast';
const Spinner = require('react-spinkit');

const req = new Request();

class FormLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    async submit(event) {
        event.preventDefault();
        this.setState({ loading: true, message: '' });
        const form = event.target;
        const data = {
            usuario: form.usuario.value || '',
            password: form.password.value || ''
        }
        const res = await req.post('login', data);
        if (res.error) {
            cogoToast.error(res.message, { position: 'top-right', heading: 'OOPS!' });
            this.setState({ loading: false });
        } else {
            if(res.token) {
                this.props.context.loginSuccess({ auth: true, token: res.token });
                this.props.history.push("/home");
            } else {
				cogoToast.error(res.message || 'Usuario o contraseña incorrectos', { position: 'top-right', heading: 'OOPS!' });
				this.setState({message: data.message, loading: false});
			}
        }
    }

    
    render() {
        return (
            <form className="column justify-center align-center full card-login" onSubmit={this.submit.bind(this)}>
                <h1 className="color-white font-double weight-regular">INICIAR SESIÓN</h1>
                <div className="white-space-32"></div>
                <div className="row full label-form align-center justify-start">
                    <i className="fas fa-user-circle color-white font-medium"></i> 
                    <p className="color-white font-regular weight-tiny">&nbsp;&nbsp;Usuario</p>
                </div>
                <input className="input-form-login" type="text" id="usuario" name="usuario" placeholder="" maxLength="256" required></input>
                <div className="white-space-8"></div>
                <div className="row full label-form align-center justify-start">
                    <i className="fas fa-lock color-white font-medium"></i> 
                    <p className="color-white font-regular weight-tiny">&nbsp;&nbsp;Contraseña</p>
                </div>
                <input className="input-form-login" type="password" id="password" name="password" placeholder="" maxLength="256" required></input>  
                
                <div className="white-space-48"></div>
                <div className="row full justify-center">
                    <button className="btn-login color-blanco" type="submit" disabled={this.state.loading}>
                        {
                            this.state.loading ?
                            <Spinner name="ball-beat" color="aqua" fadeIn="none"/> : <span>Ingresar </span>
                        }
                        
                    </button>
                </div>
            </form>
        )
    }
}

export default withRouter(Consumer(FormLogin));