import React, { useState, useEffect } from 'react';
import { Consumer } from '../../context/index';

const PresupuestoForm = (props) => {
	const [state, setState] = useState({
		cliente: {},
		categoria: {},
		recipients: [],
		gifts: [],
		estado: 'CREATED'
	});

    useEffect(() => {
        const { context } = props;
        if (props.getValues) {
            props.getValues({
				estado: state.estado,
			});
		}

		if (props.values) {
			const { values } = props;
			const recipients = values.cliente? Object.keys(values.cliente) : [];

			setState({
				...state,
				cliente: values.cliente,
				recipients,
				categoria: values.categoria || {},
				gifts: values.gifts || [],
				estado: values.estado,
				mensaje: values.mensaje,
				ocasion: values.ocasion
			})
		}

    }, [props.values]);

    return (
        <div className="full row align-center presupuesto-form">
            <div className="column full">
				<div className="input-form-content column full align-start">
                    <div className="column label-medium">
                        <p> Category:</p>
                    </div>
					<div className="column full">
						<ul className="data-list">
							<li className="data-list-item">Option: {state.categoria.nombre}</li>
							<li className="data-list-item">Price: {state.categoria.precio}</li>
						</ul>
					</div>
                </div>
				<div className="input-form-content column full align-start">
                    <div className="column label-medium">
                        <p> Recipient Info:</p>
                    </div>
					<div className="column full">
						<ul className="data-list">
							{state.recipients.map(c => {
								return (
									<li key={c} className="data-list-item">{c.toUpperCase()}: &nbsp; {state.cliente[c]}</li>
								)
							})}
						</ul>
					</div>
                </div>
				<div className="white-space-16"></div>
				<div className="input-form-content column full align-start">
                    <div className="column label-medium ">
                        <p> Gifts :</p>
                    </div>
                    <div className="column full">
						<ul className="data-list">
							{state.gifts.map(g => {
								return (
									<li key={g.id_producto} className="data-list-item row full">{g.nombre} {g.precio}</li>
								)
							})}
						</ul>
                    </div>
                </div>
                <div className="white-space-16"></div>
				<div className="input-form-content column full align-start">
                    <div className="column label-medium">
                        <p>Card Messge:</p>
                    </div>
					<div className="column full">
						<ul className="data-list">
							<li className="data-list-item row full">
								OCASSION: &nbsp; {state.ocasion}
							</li>
							<li className="data-list-item row full">
								MESSAGE: &nbsp; {state.mensaje}
							</li>
						</ul>
					</div>
                </div>
				<div className="white-space-16"></div>
				<div className="input-form-content row full align-start">
                    <div className="column label-medium ">
                        <p> Status :</p>
                    </div>
                    <div className="column full">
                        <select
							value={state.estado}
							className="input input-modals"
							onChange={(event) => setState({ ...state, estado: event.target.value })}>
							<option value="CREATED">CREATED</option>
							<option value="DELIVERY">DELIVERY</option>
							<option value="COMPLETED">COMPLETED</option>
							<option value="CANCELLED">CANCELLED</option>
						</select>
                    </div>
                </div>
				<div className="white-space-32"></div>
            </div>
        </div>
    )
}

export default Consumer( PresupuestoForm );