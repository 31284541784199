import React from 'react';
import Sidebar from "../components/sideBar";
import TablaClientes from "../components/tables/clientesTabla";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import ClienteForm from "../components/clientes/form";
import { Consumer } from '../context';
// TOASR ALERTS & LOADING SPINERS
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import Request from '../api/apiService';
const req = new Request();

class Clientes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			id_modulo: 8,
			loading: false,
			lectura: false,
			escritura: false,
            clientes: []
		}
		this.formValues = {};
    }

    componentDidMount() {
	    this.loadUser();
	}
    
    openModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
    }

	async loadUser() {
        const aroos_user = window.localStorage.getItem("aroos_user");
		let session = JSON.parse(aroos_user);

		const data = {
            token: session.token
		}
        const { token } = await req.post('decode', data);
        if (token) {
			this.setState({usuario: token});
			const { permisos } = token;
			if(permisos) {
				if(permisos.lectura.find(l => l === this.state.id_modulo)) {
					this.setState({lectura: true});
				}
				if(permisos.escritura.find(e => e === this.state.id_modulo)) {
					this.setState({escritura: true});
				}
				this.loadClientes();
			}
		} else {
			cogoToast.error('No se pudieron obtener los permisos.', { position: 'top-right', heading: 'OOPS!' });
		}
	}

    async loadClientes() {
		this.setState({loading: true, message: ''});
		if (!this.state.lectura) {
			cogoToast.error('No tiene permisos de lectura.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({loading: false});
			return;
		}
        const data = {
            id_modulo: this.state.id_modulo
        }

        const res = await req.get('clientes/tabla', data);
        this.setState({message: res.message});
        if(res) {
            this.setState({ clientes: res.clientes });
        } else {
            cogoToast.error('No se pudieron obtener los clientes.', { position: 'top-right', heading: 'OOPS!' });
        }
        this.setState({loading: false});
    }

    reload() {
        this.loadUser();
    }

    async addNewCliente(event) {
        this.setState({loading: true, message: ''});
		event.preventDefault();
		const { context } = this.props;
		const form = event.target;
		const data = {
			id_modulo: this.state.id_modulo,
			nombre: form.nombre.value,
			empresa: form.empresa.value,
			cargo: form.cargo.value,
			token: context.token,
			fecha_creado: moment().format('YYYY-MM-DD HH:mm:ss')
		}
		const res = await req.post('cliente/crear', data);
		if(res.created) {
			cogoToast.success('Nuevo cliente agregado', { position: 'top-right', heading: 'AGREGADO!' });
			this.closeModal('nuevoCliente');
			this.reload();
		} else {
			cogoToast.error(res.message || 'Error Interno.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({message: data.message, loadingSave: false});
		}
        this.setState({loading: false});
    }

    render() {
        let clientes = [];
		if(this.state.clientes) {
			clientes = this.state.clientes;
			//console.log('DATEEEE', products)
		}
        return (
            <div className="row full view">
                <Sidebar view="usuarios"></Sidebar>
                <div className="column full align-center">
                    <div className="container column">

                        <div className="row full header-options align-center">
                            <div className="column full title">
                                <h1 className="weight-light color-white">Clientes</h1>
                            </div>
                            <div className="column full btns-options align-end">
                                <div className="row">
                                
                                <button className="btn-options btn-orange-plus" onClick={this.openModal.bind(this,'nuevoCliente')} >
                                    <i className="fas fa-plus font-regular"></i>
                                </button>
                                </div>
                            </div>
                        </div>
                        <TablaClientes
							clientes	=	{clientes}
							loading		=	{this.state.loading}
							reload		=	{this.reload.bind(this)}/>
                    </div>
                </div>
                <DinamicModal 
					idModal					=	{'nuevoCliente'}
					sizeModalContainer		=	{'small'}
					title					=	{'Crear Cliente'} 
					showBtnSuccess			=	{true}
					btnTextSuccess			=	{"GUARDAR"}
					showBtnCancel			=	{true}
                    btnTextCancel			=	{"CANCELAR"}
                    success                 =   {this.addNewCliente.bind(this)}
					loadingSave				=	{this.state.loading}
					>
                        <ClienteForm />
                </DinamicModal>
            </div>
        )
    }
}

export default Consumer(Clientes);
