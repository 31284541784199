import React from 'react';
import Sidebar from "../components/sideBar";
import TableProductos from "../components/tables/productosTabla";
import DinamicModal from "../components/dinamicModal/dinamicModal";
import ProductoForm from "../components/products/form";
import { Consumer } from '../context';
// TOASR ALERTS & LOADING SPINERS
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';
import Request from '../api/apiService';

const req = new Request();

class Presupuestos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
			id_modulo: 9,
			loading: false,
			lectura: false,
			escritura: false,
			presupuestos: [],
			categorias: []
		}
		this.formValues = {};
    }

    componentDidMount() {
		this.loadUser();
		this.loadTipos();
		this.loadCategorias();
	}
    
    openModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
	}

	closeModal(idModal) {
		const modal = document.getElementById(idModal);
		if(modal) {
			modal.classList.add('hide-dinamic-modal');
			modal.classList.remove('show-dinamic-modal');
		}
    }

	async loadUser() {
        const aroos_user = window.localStorage.getItem("aroos_user");
		let session = JSON.parse(aroos_user);

		const data = {
            token: session.token
		}
        const { token } = await req.post('decode', data);
        if (token) {
			this.setState({usuario: token});
			const { permisos } = token;
			if(permisos) {
				if(permisos.lectura.find(l => l === this.state.id_modulo)) {
					this.setState({lectura: true});
				}
				if(permisos.escritura.find(e => e === this.state.id_modulo)) {
					this.setState({escritura: true});
				}
				this.loadProductos();
			}
		} else {
			cogoToast.error('Could not get permissions.', { position: 'top-right', heading: 'OOPS!' });
		}
	}

    async loadProductos() {
		this.setState({loading: true, message: ''});
		if (!this.state.lectura) {
			cogoToast.error('You dont have read permissions.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({loading: false});
			return;
		}
        const data = {
            id_modulo: this.state.id_modulo
        }

        const res = await req.get('admin/productos/tabla', data);
        this.setState({message: res.message});
        if(res) {
			if(res.error) {
				cogoToast.error(res.message, { position: 'top-right', heading: 'OOPS!' });
			}
            this.setState({ productos: res.productos });
        } else {
            cogoToast.error('Could not get permissions.', { position: 'top-right', heading: 'OOPS!' });
        }
        this.setState({loading: false});
    }

	async loadTipos() {
        const data = {
            id_modulo: this.state.id_modulo
        }
        const res = await req.get('tipos/lista', data);
        this.setState({message: res.message});
        if(res) {
            this.setState({ tipos: res.tipos });
        } else {
            cogoToast.error('No se pudo obtener la lista de tipos.', { position: 'top-right', heading: 'OOPS!' });
        }
	}

	async loadCategorias() {
        const data = {
            id_modulo: this.state.id_modulo
        }
        const res = await req.get('categorias/lista', data);
        this.setState({message: res.message});
        if(res) {
            this.setState({ categorias: res.categorias });
        } else {
            cogoToast.error('No se pudo obtener la lista de categorias.', { position: 'top-right', heading: 'OOPS!' });
        }
    }

    reload() {
        this.loadUser();
    }

    async addNewProducto(event) {
        this.setState({loading: true, message: ''});
		event.preventDefault();
		const { context } = this.props;
		const data = {
			id_modulo: this.state.id_modulo,
			id_tipo: this.formValues.id_tipo,
			id_categoria: this.formValues.id_categoria,
			nombre: this.formValues.nombre,
			descripcion: this.formValues.descripcion,
			precio: this.formValues.precio,
			activo: this.formValues.activo,
			categorias: this.formValues.categorias,
			token: context.token,
			fecha_creado: moment().format('YYYY-MM-DD HH:mm:ss')
		}
		const res = await req.post('admin/producto/crear', data);
		if(res.created) {
			cogoToast.success('New product created', { position: 'top-right', heading: 'SUCCESS!' });
			this.closeModal('nuevoProducto');
			this.reload();
		} else {
			cogoToast.error(res.message || 'Internal error.', { position: 'top-right', heading: 'OOPS!' });
			this.setState({message: data.message, loadingSave: false});
		}
        this.setState({loading: false});
    }

    render() {
        let productos = [];
		if(this.state.productos) {
			productos = this.state.productos;
			//console.log('DATEEEE', products)
		}
        return (
            <div className="row full view">
                <Sidebar view="usuarios"></Sidebar>
                <div className="column full align-center">
                    <div className="container column">

                        <div className="row full header-options align-center">
                            <div className="column full title">
                                <h1 className="weight-light color-white">Products</h1>
                            </div>
                            <div className="column full btns-options align-end">
                                <div className="row">
                                
                                <button className="btn-options btn-orange-plus" onClick={this.openModal.bind(this,'nuevoProducto')} >
                                    <i className="fas fa-plus font-regular"></i>
                                </button>
                                </div>
                            </div>
                        </div>
                        <TableProductos
							productos	=	{productos}
							loading		=	{this.state.loading}
							reload		=	{this.reload.bind(this)}
							clientes	=	{this.state.clientes}/>
                    </div>
                </div>
					<DinamicModal 
						idModal					=	{'nuevoProducto'}
						sizeModalContainer		=	{'big'}
						title					=	{'New Product'} 
						showBtnSuccess			=	{true}
						btnTextSuccess			=	{"Save"}
						showBtnCancel			=	{true}
						btnTextCancel			=	{"Cancel"}
						loadingSave				=	{this.state.loading}
						success                 =   {this.addNewProducto.bind(this)}
						loadingSave				=	{this.state.loading}
						>
							<ProductoForm
								getValues		=	{(values) => this.formValues = values}
								categorias		=	{this.props.categorias}
								upImagenes		=	{true}
								idModalImagen	=	{'image-modal-nuevo'}/>
					</DinamicModal>
            </div>
        )
	}
}

export default Consumer(Presupuestos);
